<template>
  <div class="step-six tutorial">
    <h2>Adding a custom domain name</h2>
    <h4>Purchasing a domain</h4>
    <p>The first step is to choose your domain name. I'd suggest getting a .com version of the domain name you want. If you have to compromise with a hyphen or slight change in the name, I'd do it. When you buy your domain make sure to get a couple of other top-level domains (.co.uk, .net) to protect your brand. Personal brands don't need to worry about this as much.</p>
    <p>I bought mine from Google Domains, which has a simple to use DNS setting, allowing me to host my domain from here rather than else where.</p>
    <h4>DNS Settings</h4>
    <p>Select the domain you bought and go to Manage -> DNS</p>
    <p>Create a new resource record with the following details: This will connect your new domain with the url given to us both azure.</p>
    <div class="content-panel">
      <div class="card bg-light">
        <div class="card-body">
          <p class="card-text"><b>Host name:</b> www</p>
          <p class="card-text"><b>Type:</b> CNAME</p>
          <p class="card-text"><b>TTL:</b> 3600</p>
          <p class="card-text"><b>Data:</b> my-azure-url-md3f23fd9.azurestaticapps.net</p>
        </div>
      </div>
    </div>
    <h4>Azure Portal</h4>
    <p>Once you've created a record it's time to switch over to the azure portal. Open Azure and go to your static web app (myapp). Search custom domains, you'll see a list of one. Click add.</p>
    <p>Enter the domain name you have setup a CNAME record for and click next. Then toggle the <i>Hostname record type</i> to CNAME and select add.</p>
    <p>If you have setup your record correctly you'll get a loading symbol in azure and eventually it will go green (active). Azure says this could take up 48 hours, but for me it was only 30 seconds.</p>
    <p>Congratualtions! Your custom domain name is now setup. </p>
  </div>
</template>
